import PropTypes from 'prop-types';
import headerHeights from 'config/branding/header-heights';
import useDynamicMainContentTop from '@activebrands/core-web/hooks/useDynamicMainContentTop';
import { styled } from '@activebrands/core-web/libs/styletron';
import { LayoutProps } from '@activebrands/core-web/types/layout';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Banner from 'components/Header/Banner';
import { StaticSweetFullLogo } from 'components/Header/StaticSweetFullLogo';
import { getHeaderTheme } from 'components/Header/getHeaderTheme';

const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
});

const Main = styled('main', {
    flex: '1 0 auto',
});

const DefaultLayout = ({ children, page = {} }: LayoutProps) => {
    const alternateHrefs = page.data?.hrefs || [];
    const transparentHeader = page.data?.content?.transparentHeader;
    const pageContent = page.data?.content || {};
    const theme = getHeaderTheme({
        heroTextColor: pageContent.heroContent?.[0]?.textColor,
        transparent: transparentHeader,
    });
    const banner = pageContent.banner?.[0] || {};
    const bannerTexts = banner?.texts?.map(text => text?.richText || text);
    const hasBanner = bannerTexts?.length > 0;
    const { bannerHeight, headerHeight } = headerHeights;
    const dynamicBannerHeight = hasBanner ? bannerHeight : 0;

    // Let content of the page fall behind the header in desktop
    const contentTopOfPage = ['page', 'noPageType'].includes(page.type);

    // Calculate the distance from the top of the page for content
    const mobileMainContentTop = '0';
    const desktopMainContentTop = `${contentTopOfPage ? 0 : headerHeight + dynamicBannerHeight}px`;

    // Update the distance from the top of the page for content
    useDynamicMainContentTop({ desktopMainContentTop, mobileMainContentTop });

    return (
        <Wrapper $style={{ marginTop: [mobileMainContentTop, null, null, null, null, desktopMainContentTop] }}>
            <Header alternateHrefs={alternateHrefs} theme={theme} />
            {hasBanner && (
                <Banner spin={banner?.spin} texts={bannerTexts} theme={banner.theme} to={banner?.link?.url} />
            )}
            {contentTopOfPage && <StaticSweetFullLogo hasBanner={hasBanner} />}
            <Main>{children}</Main>
            <Footer alternateHrefs={alternateHrefs} />
        </Wrapper>
    );
};

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    page: PropTypes.object.isRequired,
};

export default DefaultLayout;
