import React from 'react';
import PropTypes from 'prop-types';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import LogotypeSymbol from 'components/icons/LogotypeSymbol';
import LogotypeText from 'components/icons/LogotypeText';

export const StaticSweetFullLogo = ({ hasBanner, $style }) => {
    const [css] = useStyletron();
    return (
        <div
            className={css({
                color: 'var(--color-text-header)',
                display: ['block', null, null, null, 'none'],
                left: '50%',
                position: 'absolute',
                top: hasBanner ? 'calc(var(--height-banner) + 12px)' : '12px',
                transform: 'translate3d(-50%, 0, 0)',
                zIndex: 'var(--zindex-top-logo)',
                ...$style,
            })}
        >
            <div className={css({ display: 'flex' })}>
                <LogotypeSymbol />
                <LogotypeText />
            </div>
        </div>
    );
};

StaticSweetFullLogo.propTypes = {
    $style: PropTypes.any,
    hasBanner: PropTypes.any,
};
